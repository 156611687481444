import type { Message } from 'google-protobuf'
import type { Request, UnaryInterceptor, UnaryResponse } from 'grpc-web'
import type { useSentry } from '#imports'

type InterceptorRequest = Request<Message, Message>
type InterceptorUnaryResponse = UnaryResponse<Message, Message>

export class MetadataInterceptor implements UnaryInterceptor<Message, Message> {
  locale: string
  region: string
  timeout: number
  sentry: ReturnType<typeof useSentry>

  constructor (sentry: ReturnType<typeof useSentry>, locale: string, region: string, timeout: number) {
    this.locale = locale
    this.region = region
    this.timeout = timeout
    this.sentry = sentry
  }

  intercept (
    request: InterceptorRequest,
    invoker: (invokerRequest: InterceptorRequest) => Promise<InterceptorUnaryResponse>
  ): Promise<InterceptorUnaryResponse> {
    const deadline = new Date()
    deadline.setSeconds(deadline.getSeconds() + this.timeout)

    request.getMetadata()['Accept-Language'] = this.locale
    // @ts-expect-error
    request.getMetadata()['X-Metadata-Region'] = request.getRequestMessage().region ?? this.region
    request.getMetadata()['deadline'] = deadline.getTime().toString()

    this.sentry.setTag('locale', this.locale)
    this.sentry.setTag('region', this.region)

    return invoker(request)
  }
}

// {"message":"Запрос не содержит id региона","context":{"uri":"/api.region.v1.RegionAPI/GetRegionByClientIP","host":"nsk.winestyle.ru","method":"POST","headers":{"host":["nsk.winestyle.ru"],"x-request-id":["16d35167c8e2da401697bb7e903f4ab2"],"x-real-ip":["37.192.215.3"],"x-forwarded-for":["37.192.215.3"],"x-forwarded-host":["nsk.winestyle.ru"],"x-forwarded-port":["443"],"x-forwarded-proto":["https"],"x-forwarded-scheme":["https"],"x-scheme":["https"],"x-original-forwarded-for":["37.192.215.3"],"content-length":["19"],"x-qrator-requestid":["2a67ffa998d39c02153350f6e545739b"],"x-qrator-ip-source":["37.192.215.3"],"x-qrator-tcp-info":["65265, 54026, 5167"],"x-q-domid":["23042"],"sec-ch-ua":["\"Chromium\";v=\"128\", \"Not;A=Brand\";v=\"24\", \"Google Chrome\";v=\"128\""],"x-user-agent":["grpc-web-javascript/0.1"],"x-grpc-web":["1"],"sec-ch-ua-platform":["\"Windows\""],"sec-ch-ua-mobile":["?0"],"user-agent":["Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/128.0.0.0 Safari/537.36"],"content-type":["application/grpc-web+proto"],"accept":["*/*"],"origin":["https://nsk.winestyle.ru"],"sec-fetch-site":["same-origin"],"sec-fetch-mode":["cors"],"sec-fetch-dest":["empty"],"referer":["https://nsk.winestyle.ru/whisky/balvenie/12y/?utm_referrer=https%3A%2F%2Fyandex.ru%2F"],"accept-encoding":["gzip, deflate, br, zstd"],"accept-language":["ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,pt;q=0.6"],"priority":["u=1, i"],"x-php-ob-level":["0"]}},"level":300,"level_name":"WARNING","channel":"app","datetime":"2024-09-03T15:18:01.144933+03:00","extra":{"request-id":"6db09faed7897a89","coroutine-id":1649204,"sentry-trace-id":""}}
