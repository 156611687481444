import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45canonical_45url_45global from "/app/middleware/01_canonical-url.global.ts";
import _02_4500_45static_45redirects_45global from "/app/middleware/02-00_static_redirects.global.ts";
import _02_4501_45pages_45redirects_45global from "/app/middleware/02-01_pages_redirects.global.ts";
import _02_4501_45region_45redirects_45global from "/app/middleware/02-01_region_redirects.global.ts";
import locale_45global from "/app/middleware/locale.global.ts";
import ymarket_45mark_45global from "/app/middleware/ymarket-mark.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45canonical_45url_45global,
  _02_4500_45static_45redirects_45global,
  _02_4501_45pages_45redirects_45global,
  _02_4501_45region_45redirects_45global,
  locale_45global,
  ymarket_45mark_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "catalog-check": () => import("/app/middleware/catalog-check.ts"),
  "catalog-first-page": () => import("/app/middleware/catalog-first-page.ts"),
  "product-check": () => import("/app/middleware/product-check.ts")
}