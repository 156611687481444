import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_PQZICtvjHK from "/app/modules/nuxt-cache/plugin.ts";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_8I4LkgGwQ1 from "/app/modules/nuxt-localization/plugin.ts";
import plugin_UhZsn9DZ4v from "/app/modules/nuxt-yandex-maps/plugin.ts";
import plugin_luXBKqpx4f from "/app/modules/nuxt-notify/plugin.ts";
import plugin_smGKsp4shu from "/app/modules/nuxt-devlogs/plugin.ts";
import plugin_client_PJfdZhJJlR from "/app/modules/nuxt-admin/plugin-client.ts";
import custom_show_lYQXFwplQl from "/app/plugins/custom-show.ts";
import modals_RaVbrZQ2Fe from "/app/plugins/modals.ts";
import vue_touch_events_P3TJLnzVGD from "/app/plugins/vue-touch-events.ts";
import app_config_zgG4dBcgoH from "/app/plugins/app-config.ts";
import plugin_jFIUWqc7wS from "/app/modules/nuxt-auth/plugin.ts";
import plugin_EOirqVKsdc from "/app/modules/nuxt-metrica/plugin.ts";
import plugin_4N9vg42XLU from "/app/modules/nuxt-api/plugin.ts";
import check_region_VeFw1wtE7z from "/app/modules/nuxt-redirects/check-region.ts";
import index_l2HcN5EU7a from "/app/modules/nuxt-metrica/flocktory/index.ts";
import index_xstx2Ujvm0 from "/app/modules/nuxt-metrica/clicky/index.ts";
import index_g6tZQ2fhHv from "/app/modules/nuxt-metrica/yandex/index.ts";
import index_FwyvMtgB1A from "/app/modules/nuxt-metrica/google/index.ts";

function __DEVLOGS_WRAPPER__ (plugin, src) {
  if (!plugin)
    return plugin

  return defineNuxtPlugin({
    ...plugin,
    async setup (...args) {
      const nuxtApp = args[0]
      const start = Date.now()
      const result = await plugin.apply(this, args)
      const end = Date.now()

      nuxtApp.hooks.callHook('plugin-metrics:log', {
        src,
        start,
        end,
        duration: end - start,
      })

      return result
    }
  })
}

export default [
__DEVLOGS_WRAPPER__(revive_payload_client_4sVQNw7RlN, "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js"),
__DEVLOGS_WRAPPER__(unhead_KgADcZ0jPj, "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js"),
__DEVLOGS_WRAPPER__(router_jmwsqit4Rs, "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js"),
__DEVLOGS_WRAPPER__(plugin_PQZICtvjHK, "/app/modules/nuxt-cache/plugin.ts"),
__DEVLOGS_WRAPPER__(payload_client_yVLowv6hDl, "/app/node_modules/nuxt/dist/app/plugins/payload.client.js"),
__DEVLOGS_WRAPPER__(navigation_repaint_client_bs2bWDGRIE, "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js"),
__DEVLOGS_WRAPPER__(check_outdated_build_client_8vK7RkfGxZ, "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js"),
__DEVLOGS_WRAPPER__(chunk_reload_client_UciE0i6zes, "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js"),
__DEVLOGS_WRAPPER__(components_plugin_KR1HBZs4kY, "/app/.nuxt/components.plugin.mjs"),
__DEVLOGS_WRAPPER__(prefetch_client_5tzzN0oIVL, "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js"),
__DEVLOGS_WRAPPER__(plugin_8I4LkgGwQ1, "/app/modules/nuxt-localization/plugin.ts"),
__DEVLOGS_WRAPPER__(plugin_UhZsn9DZ4v, "/app/modules/nuxt-yandex-maps/plugin.ts"),
__DEVLOGS_WRAPPER__(plugin_luXBKqpx4f, "/app/modules/nuxt-notify/plugin.ts"),
__DEVLOGS_WRAPPER__(plugin_smGKsp4shu, "/app/modules/nuxt-devlogs/plugin.ts"),
__DEVLOGS_WRAPPER__(plugin_client_PJfdZhJJlR, "/app/modules/nuxt-admin/plugin-client.ts"),
__DEVLOGS_WRAPPER__(custom_show_lYQXFwplQl, "/app/plugins/custom-show.ts"),
__DEVLOGS_WRAPPER__(modals_RaVbrZQ2Fe, "/app/plugins/modals.ts"),
__DEVLOGS_WRAPPER__(vue_touch_events_P3TJLnzVGD, "/app/plugins/vue-touch-events.ts"),
__DEVLOGS_WRAPPER__(app_config_zgG4dBcgoH, "/app/plugins/app-config.ts"),
__DEVLOGS_WRAPPER__(plugin_jFIUWqc7wS, "/app/modules/nuxt-auth/plugin.ts"),
__DEVLOGS_WRAPPER__(plugin_EOirqVKsdc, "/app/modules/nuxt-metrica/plugin.ts"),
__DEVLOGS_WRAPPER__(plugin_4N9vg42XLU, "/app/modules/nuxt-api/plugin.ts"),
__DEVLOGS_WRAPPER__(check_region_VeFw1wtE7z, "/app/modules/nuxt-redirects/check-region.ts"),
__DEVLOGS_WRAPPER__(index_l2HcN5EU7a, "/app/modules/nuxt-metrica/flocktory/index.ts"),
__DEVLOGS_WRAPPER__(index_xstx2Ujvm0, "/app/modules/nuxt-metrica/clicky/index.ts"),
__DEVLOGS_WRAPPER__(index_g6tZQ2fhHv, "/app/modules/nuxt-metrica/yandex/index.ts"),
__DEVLOGS_WRAPPER__(index_FwyvMtgB1A, "/app/modules/nuxt-metrica/google/index.ts")
]
